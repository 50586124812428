<template>
<b-row>
  <b-col cols="12" xl="4" class="nopadding">
    <b-card>
      <div slot="header">
        <strong># {{$t('common.new_object',{obj:object_text_name})}} </strong>
      </div>
      <div class="text-center">
        <img v-if="obj.logo" :src="computed_logo(obj.logo)"
        class="avatar img-circle img-thumbnail" alt="avatar">
        <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="avatar img-circle img-thumbnail" alt="avatar">
        <h6>{{$t('common.choose_another_image')}}</h6>
        <input type="file" class="text-center center-block file-upload"
        @change="getImage" id="object_fileControl">
      </div>
      <br>
      <div class="row">
        <div class="col bg-secondary">
          <b-form-group>
            <label for="input_name">{{$t('common.name')}}</label>
            <div v-if="$v.obj.name.$error" class="pull-right">
              <div class="text-danger" v-if="!$v.obj.name.required">{{$t('common.name_required')}}</div>
              <div class="text-danger" v-else-if="!$v.obj.name.isUnique">{{$t('common.name_existing')}}</div>
            </div>
            <b-form-input type="text" id="input_name" :placeholder="$t('common.placeholder_name')"
            v-model="obj.name" maxlength="50" ref="txt_object_name" size="sm"
            v-on:input="$v.obj.name.$touch"></b-form-input>
          </b-form-group>
          <b-form-group>
            <label for="input_code">{{$t('common.code')}}</label>
            <div v-if="$v.obj.code.$error" class="pull-right">
              <div class="text-danger" v-if="!$v.obj.code.required">{{$t('common.code_required')}}</div>
              <div class="text-danger" v-else-if="!$v.obj.code.isUnique">{{$t('common.code_existing')}}</div>
            </div>
            <b-form-input type="text" id="input_code" :placeholder="$t('common.placeholder_code')"
            v-on:input="$v.obj.code.$touch" size="sm"
            v-model="obj.code" maxlength="25"></b-form-input>
          </b-form-group>
          <b-form-group v-show="business=='vlxd'">
            <label for="input_owner">{{$t('common.owner')}}</label>
            <div v-if="$v.obj.owner.$error" class="pull-right">
              <div class="text-danger" v-if="!$v.obj.owner.required">{{$t('common.owner_required')}}</div>
            </div>
            <b-form-input type="text" id="input_owner" :placeholder="$t('common.placeholder_owner')"
            v-model="obj.owner" maxlength="50" ref="txt_object_owner" size="sm"
            v-on:input="$v.obj.owner.$touch"></b-form-input>
          </b-form-group>
          <b-form-group v-show="business=='hotel'||business=='hostel'||business=='karaoke'||business=='bida'||business=='spa'||business=='football'">
            <label for="ddl_object_type">{{$t('common.type')}}</label>
            <div v-if="$v.obj.type_id.$error" class="pull-right">
              <div class="text-danger" v-if="!$v.obj.type_id.required">{{$t('common.type_required')}}</div>
            </div>
            <b-form-select v-model="obj.type_id"
              id="ddl_object_type" size="sm">
              <option value="null" disabled>{{$t('common.select_type')}}</option>
              <option v-for="c in ls_type_ddl" :value="c.id"
              v-bind:key="c.id">{{c.name}}</option>
            </b-form-select>
          </b-form-group>
          <b-form-group v-show="business=='hotel'||business=='karaoke'||business=='bida'||business=='spa'||business=='football'||business=='vlxd'">
            <label for="input_price">{{$t('common.price')}}</label>
            <div v-if="$v.obj.price.$error" class="pull-right">
              <div class="text-danger" v-if="!$v.obj.price.required">{{$t('common.price_required')}}</div>
            </div>
            <money v-model="obj.price" v-bind="money" class="form-control"
              id="input_price" maxLength="18"></money>
          </b-form-group>
          <div class="row" v-show="business=='vlxd'">
            <div class="col">
              <b-form-group>
                <label for="input_start">{{$t('common.start_date')}}</label>
                <div v-if="$v.obj.start.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.obj.start.required">{{$t('common.date_required')}}</div>
                </div>
                <date-picker v-model="obj.start" :config="options" class="date-picker-height"></date-picker>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group>
                <label for="input_end">{{$t('common.end_date')}}</label>
                <div v-if="$v.obj.end.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.obj.end.required">{{$t('common.date_required')}}</div>
                </div>
                <date-picker v-model="obj.end" :config="options" class="date-picker-height"></date-picker>
              </b-form-group>
            </div>
          </div>
          <div class="row" v-show="business=='vlxd'">
            <div class="col">
              <b-form-group>
                <label for="txt_object_phone">{{$t('common.phone')}}</label>
                <div v-if="$v.obj.phone.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.obj.phone.numeric">{{$t('common.phone_number_only')}}</div>
                  <div class="text-danger" v-else-if="!$v.obj.phone.minLength">{{$t('common.phone_ten_number')}}</div>
                </div>
                <b-form-input type="text" id="txt_object_phone"
                v-model.trim="obj.phone" maxlength="15" size="sm"
                v-on:input="$v.obj.phone.$touch" :disabled="obj.client_id!=null"
                :placeholder="$t('common.placeholder_phone')"></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group>
                <label for="txtEmail">{{$t('common.email')}}</label>
                <div v-if="$v.obj.email.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.obj.email.email">{{$t('common.email_invalid')}}</div>
                </div>
                <b-form-input type="email" id="txtEmail"
                v-model.trim="obj.email" maxlength="50" size="sm"
                v-on:input="$v.obj.email.$touch" :disabled="obj.client_id!=null"
                :placeholder="$t('common.placeholder_email')"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <b-form-group v-show="business!='vlxd'">
            <label for="input_so_object">{{$t('common.number')}}</label>
            <b-form-input type="number" id="input_name" :placeholder="$t('common.placeholder_number',{obj:object_text_name})"
            v-model="obj.number" maxlength="10" size="sm"></b-form-input>
          </b-form-group>
          <b-form-group v-show="business!='vlxd'">
            <label for="ddl_object_type">{{$t('common.staff')}}</label>
            <b-form-select v-model="obj.user_serve_id"
              id="ddl_object_type" size="sm">
              <option value="null" disabled>{{$t('common.select_staff')}}</option>
              <option v-for="c in ls_user_ddl" :value="c.id"
              v-bind:key="c.id">{{c.name}}</option>
            </b-form-select>
          </b-form-group>
          <div class="form-group" v-show="business=='hotel'">
            <fieldset class="scheduler-border col-md-12">
              <legend class="scheduler-border">{{$t('common.rent_type')}}</legend>
              <div class="row d-flex justify-content-between align-items-center"
              v-for="(itm, k) in $v.obj.rents.$each.$iter" :key="k">
                <div class="form-group col-md-6 form-fixer">
                  <label :for="'txt_name'+k">{{$t('common.type')}}</label>
                  <div v-if="$v.$error" class="pull-right">
                    <div class="text-danger" v-if="!itm.name.required">{{$t('common.type_equired')}}</div>
                  </div>
                  <b-form-input type="text" id="txt_name" :placeholder="$t('common.object_name_ex')"
                  class="form-control" :disabled="itm.status.$model<0"
                  v-model.trim="itm.name.$model" maxlength="50" size="sm"></b-form-input>
                </div>
                <div class="form-group col-md-4 form-fixer">
                  <label :for="'txt_price_'+k">{{$t('common.price')}}</label>
                  <div v-if="$v.$error" class="pull-right">
                    <div class="text-danger" v-if="!itm.price.required">{{$t('common.price_required')}}</div>
                  </div>
                  <money v-model.trim="itm.price.$model" v-bind="money" class="form-control"
                  :name="'txt_price_'+k" :id="'txt_price_'+k" :disabled="itm.status.$model<0"
                  maxLength="15"></money>
                </div>

                <div class="col-md-2 pull-right text-white" v-if="itm.status.$model>-1">
                  <div v-if="obj.id==null">
                    <a class="btn btn-danger mr-1 btn-sm" v-if="obj.rents.length>1"
                      @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                    <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                  </div>
                  <div v-else>
                    <a class="btn btn-danger mr-1 btn-sm"
                    @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                    <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                  </div>
                </div>
                <div class="col-md-2 pull-right text-white" v-else>
                  <div>
                    <a class="btn btn-success mr-1 btn-sm" v-if="obj.rents.length>1"
                      @click="itm.status.$model=0"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <b-form-group v-show="business=='hotel'||business=='karaoke'||business=='bida'||business=='spa'||business=='football'">
            <label for="input_dientich">{{$t('common.square')}}</label>
            <b-form-input type="text" id="input_dientich" :placeholder="$t('common.placeholder_square')"
            v-model="obj.dientich" maxlength="10" size="sm"></b-form-input>
          </b-form-group>
          <div class="row" v-show="business=='vlxd'">
            <div class="col">
              <b-form-group>
                <label for="txt_object_address">{{$t('common.address')}}</label>
                <div v-if="$v.obj.address.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.obj.address.email">{{$t('common.address_required')}}</div>
                </div>
                <b-form-textarea type="text" id="txt_object_address" size="sm"
                v-model.trim="obj.address" maxlength="200"
                :placeholder="$t('common.placeholder_address')"></b-form-textarea>
              </b-form-group>
            </div>
          </div>
          <b-form-group>
            <label for="txt_des">{{$t('common.description')}}</label>
            <b-form-textarea type="text" id="txt_des"
            v-model.trim="obj.des" maxlength="200"
            :placeholder="$t('common.placeholder_description')"></b-form-textarea>
          </b-form-group>
        </div>
      </div>
      <div class="row pull-right">
        <b-button class="mt-1" variant="outline-success" size="sm" @click.prevent="clear()"><span class="icon is-small">
          <i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</span></b-button>
        <b-button class="ml-2 mt-1" size="sm" variant="outline-primary" @click.prevent="save_object()"><span class="icon is-small">
          <i class="fa fa-check"></i> {{$t('common.button.save')}} </span></b-button>
      </div>
    </b-card>
  </b-col>
  <b-col cols="12" xl="8" class="nopadding">
    <transition name="slide">
      <b-card>
        <div slot="header"><strong><i class="fa fa-list" aria-hidden="true"></i> {{$t('common.object_list',{obj:object_text_name})}}</strong></div>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter_object" maxlenght="50" @keyup.enter="apply_filter_object">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter_object"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page_object"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="company" slot-scope="data">
            <label v-if="data.item.company">{{data.item.company.name}}</label>
            <label v-else></label>
          </template>
          <template slot="type" slot-scope="data">
            <label v-if="data.item.type">{{data.item.type.name}}</label>
            <label v-else></label>
          </template>
          <template slot="user_serve" slot-scope="data">
            <label v-if="data.item.user_serve">{{data.item.user_serve.name}}</label>
            <label v-else></label>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)">
                <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count_object"
                        :page="page_object"
                        @change="change_page_object">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
    </transition>
  </b-col>
  <b-modal id="viewModal" title="Thông báo" header-bg-variant="info" no-body ok-only>
    <div class="h3">{{$t('common.image_error')}}</div>
  </b-modal>
</b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }

  legend.scheduler-border {
      font-size: 1.2em !important;
      font-weight: bold !important;
      text-align: left !important;
      width:auto;
      padding:0 10px;
      border-bottom:none;
  }
</style>
<script>
  import { validationMixin } from 'vuelidate'
  import { required,requiredIf, minLength,maxLength,email,url,numeric } from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'
  import datePicker from 'vue-bootstrap-datetimepicker'
  import moment from 'moment'
  import Pager from '../Pager'
  import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
  import { Role } from '@/helpers/role.js'
  import { uuid } from 'vue-uuid'
  import { Business } from '../../helpers/business'

  export default {
    components: { datePicker,Pager },
    mixins: [validationMixin],
    props: {
      hover: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '$ ',
          precision: 0,
          masked: false
        },
        processing:false,
        obj: {
          id:null,
          logo:null,
          name:null,
          owner:null,
          start:moment().format('DD-MM-YYYY HH:mm:ss'),
          end:moment().add(3,'month').format('DD-MM-YYYY HH:mm:ss'),
          code:moment().valueOf().toString(),
          user_create_id:null,
          user_update_id:null,
          company_id:null,
          branch_id:null,
          price:0,
          type_id:null,
          long:0,
          wide:0,
          height:0,
          square:0,
          phone:null,
          email:null,
          address:null,
          des:null,
          rents:[],
          user_serve_id:null
        },
        ls_obj_ddl:[],
        options: {
          format: 'DD-MM-YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true
        }
      }
    },
    validations: {
      obj: {
        name:{
          required,
          maxLength:maxLength(50),
          async isUnique(value) {
            if (value==null) return true
            if(this.obj && this.obj.id) return true

            const res = await this.checkNameUnique({name:value,company_id:this.user_login.company_id})
            return Boolean(res.data)
          }
        },
        owner:{
          required: requiredIf(function () {
            return this.business==Business.vlxd
          }),
          maxLength:maxLength(100)
        },
        start:{
          required: requiredIf(function () {
            return this.business==Business.vlxd
          })
        },
        end:{
          required: requiredIf(function () {
            return this.business==Business.vlxd
          })
        },
        address:{
          required: requiredIf(function () {
            return this.business==Business.vlxd
          })
        },
        email:{
          email,
          maxLength:maxLength(50),
        },
        phone:{
          numeric,
          maxLength:maxLength(15),
          minLength:minLength(10)
        },
        code:{
          required,
          maxLength:maxLength(25),
          async isUnique(value) {
            debugger
            if (value==null) return true
            if(this.obj && this.obj.id) return true
            debugger
            const res = await this.checkCodeUnique({code:value,company_id:this.user_login.company_id})
            return Boolean(res.data)
          }
        },
        price:{
          required: requiredIf(function () {
            return (this.business==Business.hotel||this.business==Business.karaoke||this.business==Business.bida||this.business==Business.football
            ||this.business==Business.spa||this.business==Business.vlxd)
          }),
          numeric
        },
        type_id:{
          required: requiredIf(function () {
            return (this.business==Business.hotel||this.business==Business.hostel||this.business==Business.karaoke||this.business==Business.bida||this.business==Business.football
            ||this.business==Business.spa)
          }),
        },
        rents: {
          required: requiredIf(function () {
            return this.business==Business.hotel
          }),
          $each: {
            name:{
              maxLength:maxLength(50),
              required: requiredIf(function () {
                return this.obj.rents.length>0
              })
            },
            price:{
              required: requiredIf(function () {
                return this.obj.rents.length>0
              })
            },
            status:{}
          }
        }
      }
    },
    computed: {
      ...mapGetters('st_object', ['ls_object','per_page_object','filter_object','page_count_object','page_object']),
      fields(){
        let fields=[]
        let user=this.$store.state.st_authentication.user
        if(user.role==Role.Super_Admin){
          fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
          fields.push({key: 'branch',label:this.$t('common.description'),sortable: true})
          fields.push({key: 'business',label:this.$t('common.description'),sortable: true})
        }
        if(user.company.business==Business.vlxd){
          fields.push({key: 'owner',label:this.$t('common.owner'),sortable: true})
        }
        fields.push({key: 'name',label:this.$t('common.name'),sortable: true, variant:'primary'})
        fields.push({key: 'code',label:this.$t('common.code'),sortable: true})

        if(user.company.business==Business.karaoke){
          fields.push({key: 'price',label:this.$t('common.price'),sortable: true})
        }

        if(user.company.business==Business.karaoke || user.company.business==Business.hotel|| user.company.business==Business.bida
        || user.company.business==Business.football|| user.company.business==Business.spa){
          fields.push({key: 'type',label:this.$t('common.type'),sortable: true})
          fields.push({key: 'square',label:this.$t('common.square'),sortable: true})
        }
        if(user.company.business!=Business.vlxd){
          fields.push({key: 'user_serve',label:this.$t('common.staff'),sortable: true})
        }

        fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
        fields.push({key: 'status',label:this.$t('common.status'),sortable: true})

        if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
          fields.push({key: 'action',label:'',sortable: false})
        }

        return fields
      },
      is_super () {
        let user= this.$store.state.st_authentication.user
        return user.role==Role.Super_Admin
      },
      user_login () {
        return this.$store.state.st_authentication.user
      },
      business () {
        let user= this.$store.state.st_authentication.user
        return user.company.business
      },
      filter:{
        get: function(){
          return this.$store.state.st_object.filter_object
        },
        set: function(text){
          this.$store.commit('st_object/set_filter',text)
        }
      },

      ls_type_ddl(){
        let ls= this.$store.state.st_type.ls_type_ddl
        debugger
        return ls
      },

      ls_user_ddl(){
        let ls= this.$store.state.st_user.ls_user_ddl
        debugger
        return ls
      },

      filteredAndSortedData:{
        get: function(){
          let vm=this
          let result = vm.$store.state.st_object.ls_object
          if (vm.filter_object) {
            result = result.filter(item =>vm.searchLike(item))
          }

          return result
        }
      },
      object_text_name(){
        let user= this.$store.state.st_authentication.user
        if(user.company.business==Business.football){
          return this.$t('common.football')
        }else if(user.company.business==Business.cafe||user.company.business==Business.food||user.company.business==Business.bida){
          return this.$t('common.table')
        }else if(user.company.business==Business.vlxd){
          return this.$t('common.project')
        }

        return this.$t('common.room')
      }
    },
    methods: {
      ...mapActions('st_object', ['get_ls_object', 'apply_filter_object','change_page_object']),
      ...mapActions('st_type', ['get_ls_type_ddl']),
      ...mapActions('st_user', ['get_ls_user_ddl']),
      async checkNameUnique(param){
        return await this.$store.dispatch(`st_object/name_unique`,param)
      },
      rowDeleted(item, type){
        if (!item || type !== 'row') return
        if (item.status === -1) return 'table-danger'
      },
      async checkCodeUnique(param){
        return await this.$store.dispatch(`st_object/code_unique`,param)
      },
      getRowCount (items) {
        return items.length
      },

      rowClicked (item) {
        if(item.status<0) return
        this.obj=item
      },

      clear() {
        this.obj={id:null,branch_id:null,company_id:null,square:null,
        long:0,wide:0,height:0,logo:null,number:null,rents:[],price:0,user_serve_id:null,email:null,
        phone:null,address:null,type_id:null,user_create_id:this.user_login.id,user_update_id:null,
        code:moment().valueOf().toString(),des:null,
        start:moment().format('DD-MM-YYYY HH:mm:ss'),
        end:moment().add(3,'month').format('DD-MM-YYYY HH:mm:ss')}
        this.$refs.txt_object_name.$el.focus()
        if(this.business==Business.hotel){
          this.addNewRow()
        }
        this.$v.$reset()
      },
      _validations () {
        return new Promise(resolve => {
          if (this.$v.$error || !this.$v.$pending) {
              return resolve()
          }
          let poll = setInterval(() => {
          if (!this.$v.$pending) {
              clearInterval(poll)
              resolve()
          }
          }, 200)
        })
      },

      async isValid () {
        this.$v.$reset()
        this.$v.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.$error)
      },

      async save_object() {
        var app = this
        debugger
        const isValid = await app.isValid()
        if (!isValid) return
        if (app.processing === true) return
        app.processing = true
        if(app.obj.id){
          app.obj.user_update_id=app.user_login.id
          app.$store.dispatch('st_object/update',app.obj)
          .then(function (resp) {
            app.get_ls_object()
            app.clear()
            app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
            app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
            app.processing = false
          })
        }else{
          app.obj.user_create_id=app.user_login.id
          if(!app.is_super){
            app.obj.company_id=app.user_login.company_id
            app.obj.branch_id=app.user_login.branch_id
            app.obj.business=app.user_login.company.business
          }
          app.$store.dispatch('st_object/create',app.obj)
          .then(function (resp) {
            app.get_ls_object()
            app.clear()
            app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
            app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
            app.processing = false
          })
        }
      },

      async recoverObj(obj){
        let app=this
        if(obj.status>=0) return
        let co=await app.$store.dispatch('st_object/recover_obj',obj.id)
        if(co && co.data.ok){
          app.get_ls_object()
          app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },
      confirmDelete(obj) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
        .then(value => {
          if(value!=true) return
          this.deleteObj(obj)
        })
        .catch(err => {
          this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
        })
      },

      async deleteObj(obj) {
        let app=this
        if(obj.status<0) return
        if(obj.status==1) {
          app.$toastr.info(app.$t("common.item_using_message",{obj:app.object_text_name}),app.$t("common.message"))
          return
        }
        let co=await app.$store.dispatch('st_object/delete_obj',obj.id)
        if(co&&co.data.ok){
          app.get_ls_object()
          app.clear()
          app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message",{obj:app.object_text_name}),app.$t("common.message"))
        }
      },

      searchLike:function(item){
        return item.name.includes(this.filter)
        || item.code.includes(this.filter)
        || item.created_at.includes(this.filter)
        || item.updated_at&&item.updated_at.includes(this.filter)
        || item.price&&item.price.toString().includes(this.filter)
        || item.square&&item.square.includes(this.filter)
        || item.des&&item.des.includes(this.filter)

        || item.long&&item.long.includes(this.filter)
        || item.wide&&item.wide.includes(this.filter)
        || item.height&&item.height.includes(this.filter)
        || item.phone&&item.phone.includes(this.filter)
        || item.address&&item.address.includes(this.filter)
        || item.number&&item.number.toString().includes(this.filter)
        || item.company&&item.company.name.includes(this.filter)
        || item.branch&&item.branch.name.includes(this.filter)
      },
      getImage:function(e){
        let app=this
        let image=e.target.files[0]
        if(image.size>102400){
          app.$bvModal.show("viewModal")
          document.getElementById("object_fileControl").value = ""
          return
        }
        let reader=new FileReader()
        reader.readAsDataURL(image)
        reader.onload=e=>{
          app.obj.logo=e.target.result
        }
      },
      addNewRow() {
        this.obj.rents.push({
          id:null,
          company_id:this.user_login.company_id,
          branch_id:this.user_login.branch_id,
          business:this.user_login.company.business,
          user_create_id:this.user_login.id,
          object_id:this.obj.id,
          name:null,
          price:0,
          status:0
        })
      },
      removeRow(k){
        if(k==null || parseInt(k)<0) return

        let itm=this.obj.rents[k]
        if(itm && itm.id){
          // confirm delete
          this.confirmRemoveDetail(k)
        }else{
          this.obj.rents.splice(k, 1)
        }
      },

      confirmRemoveDetail(k) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
        .then(value => {
          if(value!=true) return
          this.obj.rents[k].status=-1
        })
        .catch(err => {
          this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
        })
      }
    },
    mounted () {
      let vm =this
      debugger
      vm.$refs.txt_object_name.$el.focus()
      if(this.business==Business.hotel){
        this.addNewRow()
      }

      vm.get_ls_object()
      vm.get_ls_type_ddl(vm.user_login.company_id)
      vm.get_ls_user_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id})
    }
  }
</script>
